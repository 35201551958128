<template>
  <div class="relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <div class="flex justify-between flex-wrap md:flex-nowwrap items-center">
        <div class="flex">
          <h2 class="font-semibold">SEO Setting</h2>
        </div>
      </div>

      <div class="mt-5">
        <form @submit.prevent="submitForm" class="card pt-2 form-control">
          <label class="label"
            ><span class="label-text text-black">Title</span></label
          >
          <input
            v-model="formState.title"
            type="text"
            class="input input-md input-bordered w-full"
          />

          <label class="label"
            ><span class="label-text text-black">Meta Description</span></label
          >
          <textarea
            v-model="formState.description"
            cols="30"
            rows="3"
            class="textarea textarea-bordered w-full"
          ></textarea>

          <label class="label"
            ><span class="label-text text-black"
              >Meta keyword (separate with ",")</span
            ></label
          >
          <input
            v-model="formState.keyword"
            type="text"
            class="input input-md input-bordered w-full"
          />

          <br />

          <a-card title="Facebook Meta Tags">
            <label class="label">
              <span class="label-text text-black">Title (og:title)</span>
            </label>
            <input
              v-model="formState['og:title']"
              type="text"
              class="input input-md input-bordered w-full"
            />

            <label class="label">
              <span class="label-text text-black"
                >Description (og:description)</span
              >
            </label>
            <textarea
              v-model="formState['og:description']"
              cols="30"
              rows="3"
              class="textarea textarea-bordered w-full"
            ></textarea>

            <label class="label">
              <span class="label-text text-black">URL (og:url)</span>
            </label>
            <input
              v-model="formState['og:url']"
              type="text"
              class="input input-md input-bordered w-full"
            />

            <label class="label">
              <span class="label-text text-black">Type (og:type)</span>
            </label>
            <input
              v-model="formState['og:type']"
              type="text"
              class="input input-md input-bordered w-full"
            />

            <label class="label">
              <span class="label-text text-black">Locale (og:locale)</span>
            </label>
            <input
              v-model="formState['og:locale']"
              type="text"
              class="input input-md input-bordered w-full"
            />

            <label class="label">
              <span class="label-text text-black">Image (og:image)</span>
            </label>
            <CustomDragDropImage
              :files="facebookImage"
              :max="1"
              class="text-black"
              clearAll="Clear All"
              @updated="handleFacebookImage"
              @fileLoading="handleFileLoading"
            />
          </a-card>

          <br />

          <a-card title="Twitter Meta Tags">
            <label class="label">
              <span class="label-text text-black">Title (twitter:title)</span>
            </label>
            <input
              v-model="formState['twitter:title']"
              type="text"
              class="input input-md input-bordered w-full"
            />

            <label class="label">
              <span class="label-text text-black"
                >Description (twitter:description)</span
              >
            </label>
            <textarea
              v-model="formState['twitter:description']"
              cols="30"
              rows="3"
              class="textarea textarea-bordered w-full"
            ></textarea>

            <label class="label">
              <span class="label-text text-black">Card (twitter:card)</span>
            </label>
            <input
              v-model="formState['twitter:card']"
              type="text"
              class="input input-md input-bordered w-full"
            />

            <label class="label">
              <span class="label-text text-black">Site (twitter:site)</span>
            </label>
            <input
              v-model="formState['twitter:site']"
              type="text"
              class="input input-md input-bordered w-full"
            />

            <label class="label">
              <span class="label-text text-black">Image (twitter:image)</span>
            </label>
            <CustomDragDropImage
              :files="twitterImage"
              :max="1"
              class="text-black"
              clearAll="Clear All"
              @updated="handleTwitterImage"
              @fileLoading="handleFileLoading"
            />
          </a-card>

          <br />
          <button
            class="btn bg-green-500 border-none mt-3"
            :disabled="isNowLoading"
          >
            Submit
          </button>
        </form>
      </div>
      <!-- end of content -->
    </div>
  </div>
</template>

<script>
import SideNavBar from "../../components/SideNavBar.vue";
import CustomDragDropImage from "../../components/CustomDragDropImage.vue";

export default {
  name: "EditSEO",
  components: { SideNavBar, CustomDragDropImage },
  computed: {
    isNowLoading() {
      return this.isLoading.length > 0;
    },
    facebookImage() {
      if (this.formState[`og:image`] != "") {
        return [this.formState[`og:image`]];
      }

      return [];
    },
    twitterImage() {
      if (this.formState[`twitter:image`] != "") {
        return [this.formState[`twitter:image`]];
      }

      return [];
    },
  },
  data() {
    return {
      isLoading: [],
      formState: {
        title: "",
        description: "",
        keyword: "",
        "og:title": "",
        "og:description": "",
        "og:url": "",
        "og:type": "",
        "og:locale": "",
        "og:image": "",
        "twitter:title": "",
        "twitter:description": "",
        "twitter:card": "",
        "twitter:site": "",
        "twitter:image": "",
      },
    };
  },
  mounted() {
    this.getSeoSetting();
  },
  methods: {
    async getSeoSetting() {
      this.isLoading.push(true);
      try {
        const response = await this.axios.get("seo/info", {
          params: {
            key: this.$route.params.page,
            model_id: this.$route.query.model_id,
          },
        });

        this.formState["title"] = response.data.value["title"] || "";
        this.formState["description"] =
          response.data.value["description"] || "";
        this.formState["keyword"] = response.data.value["keyword"] || "";
        this.formState["og:title"] = response.data.value["og:title"] || "";
        this.formState["og:description"] =
          response.data.value["og:description"] || "";
        this.formState["og:url"] = response.data.value["og:url"] || "";
        this.formState["og:type"] = response.data.value["og:type"] || "";
        this.formState["og:locale"] = response.data.value["og:locale"] || "";
        this.formState["og:image"] = response.data.value["og:image"] || "";
        this.formState["twitter:title"] =
          response.data.value["twitter:title"] || "";
        this.formState["twitter:description"] =
          response.data.value["twitter:description"] || "";
        this.formState["twitter:card"] =
          response.data.value["twitter:card"] || "";
        this.formState["twitter:site"] =
          response.data.value["twitter:site"] || "";
        this.formState["twitter:image"] =
          response.data.value["twitter:image"] || "";

        this.isLoading.pop();
      } catch (error) {
        this.isLoading.pop();
        throw error;
      }
    },
    async submitForm() {
      this.isLoading.push(true);

      try {
        const response = await this.axios.put(
          "seo/info",
          {
            value: this.formState,
          },
          {
            params: {
              key: this.$route.params.page,
              model_id: this.$route.query.model_id,
            },
          }
        );

        this.isLoading.pop();
        this.$swal.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "success",
          title: "SEO settings updated",
        });
        this.$router.go(-1);
        return;
      } catch (error) {
        this.isLoading.pop();
        this.$swal.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: "Failed to update SEO setting",
        });
        throw error;
      }
    },
    handleFacebookImage(files) {
      this.formState[`og:image`] = files.length > 0 ? files[0] : "";
    },
    handleTwitterImage(files) {
      this.formState[`twitter:image`] = files.length > 0 ? files[0] : "";
    },
    handleFileLoading(isLoading) {
      if (isLoading) {
        this.isLoading.push(true);
      } else {
        this.isLoading.pop();
      }
    },
  },
};
</script>

<style>
</style>